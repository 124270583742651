import axiosInstance from "./axiosConfig";
import { token } from "./index";

export const deleteUser = async (id) => {
  const response = await axiosInstance.delete("user/" + id, {
    data: {
      api_token: token(),
    }
    
  });
  return response;
};

export const updateUsername = async (data, username) => {
  const response = await axiosInstance.put("user/" + username, {
    api_token: token(),
    username: data.username,
  });
  return response;
};

export const getInstagramUrl = async () => {
  const response = await axiosInstance.get("auth/instagram/geturl", {
    params: {
      api_token: token(),
    },
  });
  return response;
};

export const callbackInstagram = async (code) => {
  const response = await axiosInstance.get("auth/instagram/callback", {
    params: {
      api_token: token(),
      code: code,
    },
  });
  return response;
};

export const isInstagramConnect = async () => {
  const response = await axiosInstance.get("auth/instagram/isconnect", {
    params: {
      api_token: token(),
    },
  });
  return response;
};

export const getAvailableCategory = async () => {
  const response = await axiosInstance.get("category", {
    params: {
      api_token: token(),
    },
  });
  return response;
};

export const userReport = async (data) => {
  const response = await axiosInstance.put("report", {
    api_token: token(),
    username: data.username,
    email: data.email,
    message: data.message,
  });
  return response;
};