import axiosInstance from './axiosConfig'
import {token} from './index'

    async function storeContent(data,typeId) {
      console.log(token())
        try {
          const response = await axiosInstance.post('content',{

              api_token: token(),
              typeId: typeId,
              data
            
          });
          console.log(response);
          return response
        } catch (error) {
          console.error(error);
        }
      }

    // async function getContent(contentId) {
    //     try {
    //       const response = await axiosInstance.get('content/'+contentId,{
    //           api_token: token(),          
    //       });
    //       console.log(response);
    //       return response
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
      export const getContent = async (contentId) => {
        const response = await axiosInstance.get('content/'+contentId,{
          api_token: token(),  
        });
        return response;
      };
                   
    async function updateContent(data,contentId) {
      console.log(data)
    //  if(typeof(data.name)==='undefined' || typeof(data.destination)==='undefined') return false;
      try {
        const response = await axiosInstance.put('content/'+contentId,{
            api_token: token(),
            data      
        });
        console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }

    async function deleteContent(contentId) {
      try {
        const response = await axiosInstance.delete('content/'+contentId,{
            api_token: token(),
        });
        console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }

    async function weightContent(contentIds) {
      try {
        const response = await axiosInstance.post('content/weight',{
            api_token: token(),
            contentIds
        });
        console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }

    async function pinContent(contentId) {
      try {
        const response = await axiosInstance.post('content/pin',{
            api_token: token(),
            contentId
        });
        console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }


      
export {storeContent,updateContent,deleteContent,weightContent,pinContent}