import axiosInstance from "./axiosConfig";
import { token } from "./index";

export const getActions = async (type_id) => {
  const response = await axiosInstance.get("actions", {
    params: {
      api_token: token(),
      type_id: type_id,
    },
  });
  return response;
};

export const getAction = async (content_id) => {
  const response = await axiosInstance.get("actions/"+content_id, {
    params: {
      api_token: token(),
    },
  });
  return response;
};
