import axiosInstance from './axiosConfig'
import {token} from './index'



    async function getThemeApi() {
      /**
       * Использую метод для получения тем в Dashboard
       */
        try {
          const response = await axiosInstance.get('theme?api_token='+token(),{
              api_token: token(),          
          });
          console.log(response);
          return response
        } catch (error) {
          console.error(error);
        }
      }
                   
    async function setTheme(type,data) {
      //console.log(type,data)
      try {
        const response = await axiosInstance.put('theme',{
            api_token: token(),
            type,
            data      
        });
        console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }

   


      
export {setTheme,getThemeApi}