import React, { useState, createContext } from 'react';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

function MainThemeContext(props) {
  return createMuiTheme({
    palette: {
        type: props,
    },
  });

}

const ThemeContext = createContext()

const ThemeLocalProvider = props => {
  const [theme,setTheme] = useState('light')


  /**
   * Я не пойму как подключить себе шрифты глобально от мака!!! Когда начну елать темы для дашборда - надо разкурить
   */
  const muiTheme ={
    mainTheme: createMuiTheme({
      palette: {
          type: theme,
      },
      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          'sans-serif',
        ].join(','),
      },

    }),
    subTheme: createMuiTheme({
      palette: {
          //type: theme==='light'?'dark':'light',
          type: theme //может и не надо делать оппозит
      },
    })
  }

  return (
    <ThemeContext.Provider value={[theme,setTheme,muiTheme]}>
      {props.children}
    </ThemeContext.Provider>
  );
}


export {MainThemeContext,ThemeLocalProvider,ThemeContext}