import axiosInstance from './axiosConfig'
import {token} from './index'

    // async function getProducts(content_id) {
    //   console.log(content_id)
    //   try {
    //     const response = await axiosInstance.get('products/'+content_id,{
    //       params: {
    //         api_token: token()
    //       }
    //     });
    //   //  console.log(response);
    //     return response
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    export const getProducts = async(content_id) =>{
      const response = await axiosInstance.get('products/'+content_id,{
        params: {
          api_token: token()
        }
      });
      return response
    }

    export const getProduct = async(product_id) =>{
      const response = await axiosInstance.get('product/'+product_id,{
        params: {
          api_token: token()
        }
      });
      return response
    }
    
    export const storeProduct = async(data) => {
      const response = await axiosInstance.post('product',{
        data,
        api_token: token(),
      });
      return response
    }

    // async function updateProduct(data,product_id) {
    //   console.log(product_id)
    //   try {
    //     const response = await axiosInstance.put('product/'+product_id,{
    //       api_token: token(),
    //       data      
    //   });
    //     return response
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    // async function updateProduct(data,product_id) {
    //   console.log(product_id)
    //   try {
    //     const response = await axiosInstance.put('product/'+product_id,{
    //       api_token: token(),
    //       data      
    //   });
    //     return response
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    export const updateProduct = async(data,product_id) => {
      const response = await axiosInstance.put('product/'+product_id,{
        data,
        api_token: token(),
      });
      return response
    }

    async function updateProductWeight(data) {
      console.log(data)
      //product/weight/123
      try {
        const response = await axiosInstance.put('product/weight/',{
          api_token: token(),
          data      
      });

        // const response = await axiosInstance.get('product/'+product_id,{
        //   params: {
        //     api_token: token()
        //   }
        // });
      //  console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }

    async function removeProduct(id) {
      console.log(id)
      try {
        const response = await axiosInstance.delete('product/'+id,{
          api_token: token(),
          id      
      });

        // const response = await axiosInstance.get('product/'+product_id,{
        //   params: {
        //     api_token: token()
        //   }
        // });
      //  console.log(response);
        return response
      } catch (error) {
        console.error(error);
      }
    }


export {
  //getProducts,
  //getProduct,
  //updateProduct,
  updateProductWeight,
  removeProduct
}