import React, { useContext, Suspense } from 'react';
import ReactDOM from 'react-dom';

//import App from './App';


import * as serviceWorker from './serviceWorker';

import LinearProgress from '@material-ui/core/LinearProgress';
import {ThemeLocalProvider} from './context/ThemeContext'
import {ApiProvider} from './context/ApiContext'
import {DragItemOrderProvider} from './context/DragItemOrderContext'

import { BrowserRouter } from 'react-router-dom';
import './i18n';

const App = React.lazy(() => import('./App'));

const username = document.getElementById("rct-username").dataset.props;

ReactDOM.render(

    <ApiProvider>
      <ThemeLocalProvider>
        <DragItemOrderProvider>
          <BrowserRouter>
            <Suspense fallback={<div style={{marginTop: '40%'}}><LinearProgress /></div>}>
              <App view_username={username} />
            </Suspense>
          </BrowserRouter>
        </DragItemOrderProvider>
      </ThemeLocalProvider>
    </ApiProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();