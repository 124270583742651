import React from 'react';
import axiosInstance from "./axiosConfig";
import { token } from "./index";

// async function getUser(username) {
//   try {
//     console.log(123)
//     const response = await axiosInstance.get("user/" + username, {
//       params: {
//         api_token: token(),
//       },
//     })
//     console.log(response);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }
//export default getUser;

export const getUser = async(username) => {
  const response = await axiosInstance.get("user/" + username, {
    params:{
      api_token: token(),
    }
  });
  return response
}

export const getInstagram = async() => {
  const response = await axiosInstance.get('auth/getinstagram',{
    params:{
      api_token: token(),
    }
  });
  return response
}

