import axios from 'axios';
import axiosInstance from './axiosConfig'
import {token} from './index'

    async function getPaymentLink(tarifId) {
        try {
          const response = await axiosInstance.get('payment/create',{
            params: {
              tarif_id:tarifId,
              api_token: token(),
            },
          });
          return response
        } catch (error) {
          console.error(error);
        }
      }
      
export {getPaymentLink}