import React, { createContext, useState, useEffect } from 'react';

const DragItemOrderContext = createContext()

const DragItemOrderProvider = props => {

    const [items,setItems] = useState(false)
    console.log(items)
    
    return (
         <DragItemOrderContext.Provider value={{items,setItems}}>
            {props.children}
        </DragItemOrderContext.Provider>
    );
}


export {DragItemOrderProvider,DragItemOrderContext}

