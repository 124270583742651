import axiosInstance from './axiosConfig'
import {token} from './index'

    async function getIntegration(username='') {
      //console.log(123)
      // console.log(token())
        try {
          const response = await axiosInstance.get('integrations/'+username,{
            params: {
              api_token: token()
            }
          });
        //  console.log(response);
          return response
        } catch (error) {
          console.error(error);
        }
      }

      async function getIntegrationbyType(typeId) {
          try {
            const response = await axiosInstance.get('integrations',{
              params: {
                api_token: token(),
                type_id: typeId
              }
            });
          //  console.log(response);
            return response
          } catch (error) {
            console.error(error);
          }
        }
      
export {getIntegration,getIntegrationbyType}