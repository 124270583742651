import axiosInstance from './axiosConfig'

/** List all types in specific group */

    async function typeGroups(groupId) {
        try {
          const response = await axiosInstance.get('typesgroup/'+groupId);
          return response
        } catch (error) {
          console.error(error);
        }
      }
      
export default typeGroups