import axios from 'axios';
import axiosInstance from './axiosConfig'
/** List all groups */

    async function typeGroups() {
        try {
          const response = await axiosInstance.get('typegroups');
          return response
        } catch (error) {
          console.error(error);
        }
      }
      
export default typeGroups