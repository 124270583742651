import React, { createContext, useState, useEffect } from "react";
import {
  getUser,
  getInstagram,
  typeGroups,
  typesGroup,
  storeContent,
  getContent,
  updateContent,
  deleteContent,
  weightContent,
  pinContent,
  getProducts,
  getProduct,
  storeProduct,
  updateProduct,
  updateProductWeight,
  removeProduct,
  getIntegration,
  getIntegrationbyType,
  getPaymentLink,
  setTheme,
  getThemeApi,
  updateUsername,
  getInstagramUrl,
  callbackInstagram,
  isInstagramConnect,
  deleteUser,
  getActions,
  getAction,
  getAvailableCategory,
  userReport,
} from "../api";

const ApiContext = createContext();

const ApiProvider = (props) => {
  return (
    <ApiContext.Provider
      value={{
        getUser,
        getInstagram,
        typeGroups,
        typesGroup,
        storeContent,
        getContent,
        storeProduct,
        updateContent,
        deleteContent,
        weightContent,
        pinContent,
        getProducts,
        getProduct,
        updateProduct,
        updateProductWeight,
        removeProduct,
        getIntegration,
        getIntegrationbyType,
        getPaymentLink,
        setTheme,
        getThemeApi,
        updateUsername,
        getInstagramUrl,
        callbackInstagram,
        isInstagramConnect,
        deleteUser,
        getActions,
        getAction,
        getAvailableCategory,
        userReport,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export { ApiProvider, ApiContext };
